import React, { useRef, useState } from 'react'
import { FaCheck, FaTimesCircle } from 'react-icons/fa';
import { AiFillEdit } from "react-icons/ai";
import classes from 'assets/styles/EditAble.module.css';
import { EDIT_RESULT_API } from 'service/apis';
import { postMethodService } from 'service';
import { useNavigate } from 'react-router-dom';
import { errorToaster, LogoutHandle, successToaster } from './Helper';

const EditAble = ({id,value,type,refresh,setRefresh}) => {
  const [isEdit,setIsEdit] = useState(false);
  const [customValue,setCustomValue] = useState(value);
  const toastId = useRef(null);
  const navigate = useNavigate();


    const editSubmitHandler = async () => {
        if(type === "three"){
            if(customValue?.length !== 3 ){
                return errorToaster("Must be 3 Digits",toastId)
            }
        }else{
            if(customValue?.length !== 2 ){
                return errorToaster("Must be 2 Digits",toastId)
            }
        }
        let data = {
            custom_lucky_number: customValue
        };
        let res = await postMethodService(EDIT_RESULT_API(id),data);
        if(res){
            if(res === "Unauthenticated"){
                return LogoutHandle(navigate);
            };
            if(res.status === "success"){
                successToaster("Success",toastId);
                setIsEdit(false);
                setRefresh(!refresh);
            }else{
                return errorToaster(res,toastId)
            };
        };
    };
  
  return (
    <div className={classes.editContainer}>
        {
            isEdit? <input type='tel' autoFocus={true} className={classes.input} value={customValue} onChange={(e) => {
                if(type === "three"){
                    if(e.target.value.length < 4){
                        setCustomValue(e.target.value)
                    };
                }else{
                    if(e.target.value.length < 3){
                        setCustomValue(e.target.value)
                    };
                }
            }} />:
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',width: '100%'}}>
                <span style={{fontSize: '16px', color: 'var(--accent-color)'}}>{customValue || '--'}</span>
                <span className={classes.editIcon}>
                    <AiFillEdit size={20} color='var(--accent-color)' onClick={() => setIsEdit(true)}/>
                </span>
            </div>
        }
        <span className={classes.editIcon}>
            {
                isEdit &&
                <>
                    <FaTimesCircle style={{marginRight: '5px', cursor: 'pointer'}} size={15} color='tomato' onClick={()=>setCustomValue("")}/>
                    <FaCheck style={{cursor: 'pointer'}} size={15} color='green' onClick={editSubmitHandler}/>
                </>
            }
        </span>
    </div>
  )
}

export default EditAble
