import React, { useEffect, useState } from 'react'
import classes from 'assets/styles/DashboardHome.module.css';
import { FiSend } from 'react-icons/fi';
import historyImg from 'assets/images/icons/history.png';
import { useNavigate } from 'react-router-dom';
import EditAble from 'utils/EditAble';
import { getMethodService } from 'service';
import { RESULT_API } from 'service/apis';
import moment from 'moment';
import Loader from 'utils/Loader';
import { createPortal } from 'react-dom';
import { TiArrowBack, TiThMenu } from "react-icons/ti";
import Sidebar from './Sidebar';

const DashboardHome = () => {
  const [isThreedLoading,setIsThreedLoading] = useState(true);
  const [isTwodLoading,setIsTwodLoading] = useState(true);
  const [threedResults,setThreedResults] = useState([]);
  const [twodResults,setTwodResults] = useState([]);
  const [refreshTwod,setRefreshTwod] = useState(false);
  const [refreshThreed,setRefreshThreed] = useState(false);
  const navigate = useNavigate();
  const currentDate = moment().format("YYYY-MM-DD");

  const fetchThreedResult = async () => {
    const res = await getMethodService(`${RESULT_API("three_d")}?pageSize=1`);
    if(res){
        setIsThreedLoading(false);
        if(res?.status === 'success'){
            setThreedResults(res?.data);
        };
    }
  };

  const fetchTwodResult = async () => {
    const res = await getMethodService(`${RESULT_API("two_d")}?date=${currentDate}`);
    if(res){
        setIsTwodLoading(false);
        if(res?.status === 'success'){
            setTwodResults(res?.data);
        };
    };
  };

  useEffect(()=>{
    fetchThreedResult();
  },[refreshThreed]);

  useEffect(()=>{
    fetchTwodResult();
  },[refreshTwod]);

  return (
    <>
        <div className={classes.homeContainer}>
            <Sidebar />
            <div style={{marginBottom: '25px', position: 'relative', zIndex: 10}}>
                <h1 className={classes.title}>
                    Indo 2D
                </h1>
                <table className={classes.table}>
                    <tbody>
                        {
                            twodResults?.length > 0 &&
                            twodResults?.map(result => 
                                <tr key={result?.id}>
                                    <td>
                                        {moment(result?.end).format("hh:mm A")}
                                    </td>
                                    <td>
                                        {
                                            result?.lucky_number ?
                                            <span style={{paddingRight: '30px'}}>{result?.lucky_number}</span>:
                                            <EditAble id={result?.id} type="two" value={result?.custom_lucky_number} refresh={refreshTwod} setRefresh={setRefreshTwod}/>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody> 
                </table>
            </div>
            <hr style={{borderColor: '#2b273880', position: 'relative', zIndex: 10 }}/>
            <div style={{marginTop: '25px', position: 'relative', zIndex: 10}}>
                <h1 className={classes.title}>
                    Indo 3D
                </h1>
                <table className={classes.table}>
                    <tbody>
                        {
                            threedResults?.length > 0 &&
                            threedResults?.map(result => 
                                <tr key={result?.id}>
                                    <td>
                                        {moment(result?.end).format("hh:mm A")}
                                    </td>
                                    <td>
                                        <EditAble id={result?.id} type="three" value={result?.custom_lucky_number} refresh={refreshThreed} setRefresh={setRefreshThreed} />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
        {
            createPortal((isThreedLoading && isTwodLoading) && <Loader />, document.getElementById('loading'))
        }
    </>
  )
}

export default DashboardHome
