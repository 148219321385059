import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    threedStockValue: {},
};

const threedStockSlice = createSlice({
    name: 'three_stock',
    initialState,
    reducers: {
         setThreeValueHandler: (state,action) => {
             state.threedStockValue = action.payload
         }
    }
});

export const {setThreeValueHandler} = threedStockSlice.actions;
export default threedStockSlice.reducer;