import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function DownloadRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://tinyurl.com/indo2dlive';
    navigate('/', { replace: true });
  }, [navigate]);

  return null; 
}

export default DownloadRedirect;
