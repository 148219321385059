import React from 'react';
import classes from 'assets/styles/HistoryList.module.css';
import moment from 'moment';
import { generatePermutations } from 'utils/Helper';

const HistoryList = ({list,lotteryType,pathname}) => {

  const _showDateFormat = (date) => {
     let formatDate = moment(date).format('DD-MM-YY');
     return formatDate;
  };

  const _dateType = (date) => {
      let formatDate = moment(date).format('ddd');
      if(lotteryType === '3D'){
        if(pathname === '/threed-history' || pathname === '/'){
          return '';
          // return `${formatDate}`;
          // return 'Date';
        }else{
          return 'Time';
        };
      }else{
        return 'Time';
      }
  }

  const _threedSetRateHighLight = (num) => {
    if(num){
      const [integerPart, decimalPart] = num.toString().split('.');
      return (
          <div>
            {
              Number(integerPart)?.toLocaleString('en-US')
            }
            .
            <span>
              <span style={{ color: 'var(--accent-color)' }}>{decimalPart[0]}</span>
              <span>{decimalPart[1]}</span>
            </span>
          </div>
      );
    }else{
      return (
        <div>--</div>
      )
    }
  };

  const _threedValueRateHighLight = (num) => {
    if(num){
      const [integerPart, decimalPart] = num.toString().split('.');
      const formattedIntegerPart = new Intl.NumberFormat().format(integerPart);
      const integerPartArray = formattedIntegerPart.split('');
      const lastDigitIndex = integerPartArray.length - 1;
      integerPartArray[lastDigitIndex] = (
          <span key={lastDigitIndex} style={{ color: 'var(--accent-color)' }}>
            {integerPartArray[lastDigitIndex]}
          </span>
      );

      return (
          <div>
            {integerPartArray.map((digit, index) => (
              <React.Fragment key={index}>{digit}</React.Fragment>
            ))}
            .
            <span>
              <span>{decimalPart[0]}</span>
              <span style={{ color: 'var(--accent-color)' }}>{decimalPart[1]}</span>
            </span>
          </div>
      );
    }else{
      return (
        <div>--</div>
      )
    }
  };

  const _twodSetRateHighLight = (num) => {
    if(num){
      const [integerPart, decimalPart] = num.toString().split('.');
      const formattedIntegerPart = new Intl.NumberFormat().format(integerPart);
      const integerPartArray = formattedIntegerPart.split('');
      const lastDigitIndex = integerPartArray.length - 1;
      integerPartArray[lastDigitIndex] = (
          <span key={lastDigitIndex} style={{ color: 'var(--accent-color)' }}>
            {integerPartArray[lastDigitIndex]}
          </span>
      );

      return (
          <div>
            {integerPartArray.map((digit, index) => (
              <React.Fragment key={index}>{digit}</React.Fragment>
            ))}
            .
            {decimalPart}
          </div>
      );
    }else {
      return (
        <div>--</div>
      )
    }
  };

  const _twodValueRateHighLight = (num) => {
    if(num){
      const [integerPart, decimalPart] = num.toString().split('.');

      return (
          <div>
            {
              Number(integerPart)?.toLocaleString('en-US')
            }
            .
            <span>
              <span>{decimalPart[0]}</span>
              <span style={{ color: 'var(--accent-color)' }}>{decimalPart[1]}</span>
            </span>
          </div>
      );
    }else{
      return (
        <div>--</div>
      )
    }
  };

  return (
    <li className={classes.listCard}>
        <div className={classes.listContainer}>
            <div>
                <div>{_dateType(list?.date)}</div>
                <div>{(pathname === '/threed-history' || lotteryType === '3D')? _showDateFormat(list?.date) : moment(list?.end).format("hh:mm A")}</div>
            </div>
            <div>
                <div>Set</div>
                {
                   ( pathname === '/threed-history' || lotteryType === '3D')?
                    _threedSetRateHighLight(list?.set):
                    _twodSetRateHighLight(list?.set)
                }
            </div>
            <div>
                <div>Value</div>
                {
                    (pathname === '/threed-history' || lotteryType === '3D')?
                    _threedValueRateHighLight(list?.value):
                    _twodValueRateHighLight(list?.value)
                }
            </div>
            {
              (pathname === '/threed-history' || lotteryType === '3D') ?
              <div>
                  <div className={classes.result} style={{color:'var(--accent-color)'}}>{list?.lucky_number || '--'}</div>
              </div>:
              <div>
                  <div>2D</div>
                  <div className={classes.result} style={{color:'var(--accent-color)'}}>{list?.lucky_number || '--'}</div>
              </div>
            }
        </div>
        {
          (pathname === '/threed-history' || lotteryType === '3D') ?
          <>
            <hr style={{width: '90%', borderColor: '#1c2035', margin: 'auto'}} />
            <div style={{color:'#FFF',padding: '10px 0', textAlign: 'center',fontSize: '15px'}}>Lottery - {generatePermutations(list?.lucky_number)}</div>
          </>:''
        }
    </li>
  )
}

export default HistoryList
