import { configureStore } from '@reduxjs/toolkit';
import typeSlice from './slices/typeSlice';
import dateSlice from './slices/dateSlice';
import authUnlockSlice from './slices/authUnlockSlice';
import twodTimesSlice from './slices/twodTimesSlice';
import threedStockSlice from './slices/threedStockSlice';

export const store = configureStore({
  reducer: {
    type: typeSlice,
    date: dateSlice,
    authUnlock: authUnlockSlice,
    twodTimes: twodTimesSlice,
    threedStock: threedStockSlice,
  },
});