import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classes from 'assets/styles/Header.module.css';
import historyImg from 'assets/images/icons/history.png';
import Tabs from 'utils/Tabs';
import { RiArrowGoBackFill } from "react-icons/ri";
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { changeDateHandler } from 'store/slices/dateSlice';
import { authUnlockHandler } from 'store/slices/authUnlockSlice';
import logo from '../assets/images/logo/logo.png';
import { BiRefresh } from 'react-icons/bi';

const Header = ({type}) => {
  const navigate = useNavigate();
  const {dateValue} = useSelector(state => state.date);
  const {lotteryType} = useSelector(state => state.type);
  const {value} = useSelector(state => state.authUnlock);
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const [keys,setKeys] = useState([]);

  let path = lotteryType === "3D"? '/threed-history': '/twod-history' ;

  const _customIcon = forwardRef(
    ({ value, onClick, className }, ref) => (
      <FaCalendarAlt style={{cursor: 'pointer'}} size={28} color='var(--text-color)' onClick={onClick} ref={ref} />
    ),
  );

  
  const unlockClickHandler = () => {
      if(keys.length !== 3){
          setKeys(prev => [...prev, 1]);
      };
  };

  useEffect(()=>{
    if(keys.length === 3){
        const countNumber = value.filter(num => num === 1).length;
        if (countNumber >= 3) {
            console.log(countNumber);
        } else {
            dispatch(authUnlockHandler([...value,...keys]));
        };
    };
},[keys]);

  useEffect(()=>{
    setKeys([]);
  },[value]);

  return (
    <>
      <header className={classes.header}>
          {
            type === 'home'?
            <div className={classes.logoContainer}>
               <img onClick={unlockClickHandler} src={logo} style={{
                width: '48px',
                cursor: 'pointer'
               }} />
               {/* <div onClick={unlockClickHandler} className={classes.logoName}>
                  Indo2D
               </div> */}
               <div>
                 <Tabs allTabs={[{
                    id: "2",
                    name: "2D",
                  },
                  {
                    id: "3",
                    name: "3D",
                  },]} />
               </div>
               <div className={classes.historyContainer}>
                  <button className={classes.appDownLoadButton} onClick={() => window.location.reload()}>Refresh <BiRefresh size="17px" style={{marginLeft: '2px', transform: 'rotate(90deg) scaleX(-1)'}} /></button>
                  <div className={classes.history} onClick={() => navigate(path)}>
                      <img src={historyImg} />
                  </div>
               </div>
            </div>:
            <div className={classes.logoContainer}>
               <RiArrowGoBackFill className={classes.back} color='var(--text-color)' size={28} onClick={() => {dispatch(changeDateHandler(moment().format('YYYY-MM-DD')));navigate('/');}} />
               {/* <div className={classes.title}>မှတ်တမ်း {pathname === '/twod-history' && `( ${moment(dateValue).format('DD/MM/YY')} )`}</div> */}
               <div className={classes.title}>{pathname === '/twod-history' && ` ${moment(dateValue).format('DD-MM-YY')} `}</div>
               <div className={classes.date}>
                  {
                      pathname === '/twod-history' &&
                     <DatePicker
                      selected={dateValue}
                      onChange={(date) => dispatch(changeDateHandler(moment(date).format('YYYY-MM-DD')))}
                      customInput={<_customIcon className="example-custom-input" />}
                      popperPlacement="bottom-end"
                    />
                  }
               </div>
            </div>
          }
      </header>
    </>
  )
}

export default Header
