import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/TwoClose.module.css';
import History from './History';
import { getMethodService } from 'service';
import { TWO_D_HISTORY_API } from 'service/apis';
import moment from 'moment';
import NotFound from 'utils/NotFound';

const TwoClose = () => {
  const [data,setData] = useState(null);
  const [time,setTime] = useState("");

  const _twodSetRateHighLight = (num) => {
    if(num){
        const [integerPart, decimalPart] = num.toString().split('.');
        const formattedIntegerPart = new Intl.NumberFormat().format(integerPart);
        const integerPartArray = formattedIntegerPart.split('');
        const lastDigitIndex = integerPartArray.length - 1;
        integerPartArray[lastDigitIndex] = (
            <span key={lastDigitIndex} style={{ color: 'var(--accent-color)' }}>
            {integerPartArray[lastDigitIndex]}
            </span>
        );
    
        return (
            <p>
                {integerPartArray.map((digit, index) => (
                    <React.Fragment key={index}>{digit}</React.Fragment>
                ))}
                .
                {decimalPart}
            </p>
        );
    }
    };

    const _twodValueRateHighLight = (num) => {
    if(num){
        const [integerPart, decimalPart] = num.toString().split('.');
        return (
            <p>
                {
                    Number(integerPart)?.toLocaleString('en-US')
                }
                .
                <span>
                    <span>{decimalPart[0]}</span>
                    <span style={{ color: 'var(--accent-color)' }}>{decimalPart[1]}</span>
                </span>
            </p>
        );
    }
    };

    const fetchTwodHistory = async () => {
        let tempTimes = [];
        const res = await getMethodService(TWO_D_HISTORY_API);
        if(res){
            if(res?.status === 'success'){
              if(res?.data?.length > 0){
                const filteredData = res?.data?.filter(
                    item => item.set !== null || item.value !== null || item.lucky_number !== null
                );
                const result = filteredData.slice(0, 4);
                setData(result)
                result?.map(el => {
                  tempTimes.push(moment(el?.end).format("hh:mm A"));
                });
              };
            };
        };
    };

    useEffect(()=>{
        fetchTwodHistory();
    },[]);


    const _twodResultSetRateHighLight = (num) => {
        if(num){
          const [integerPart, decimalPart] = num.toString().split('.');
          const formattedIntegerPart = new Intl.NumberFormat().format(integerPart);
          const integerPartArray = formattedIntegerPart.split('');
          const lastDigitIndex = integerPartArray.length - 1;
          integerPartArray[lastDigitIndex] = (
              <span key={lastDigitIndex} style={{ color: 'var(--accent-color)' }}>
                {integerPartArray[lastDigitIndex]}
              </span>
          );
    
          return (
              <div>
                {integerPartArray.map((digit, index) => (
                  <React.Fragment key={index}>{digit}</React.Fragment>
                ))}
                .
                {decimalPart}
              </div>
          );
        }else {
          return (
            <div>--</div>
          )
        }
    };
    
    const _twodResultValueRateHighLight = (num) => {
        if(num){
          const [integerPart, decimalPart] = num.toString().split('.');
    
          return (
              <div>
                {
                  Number(integerPart)?.toLocaleString('en-US')
                }
                .
                <span>
                  <span>{decimalPart[0]}</span>
                  <span style={{ color: 'var(--accent-color)' }}>{decimalPart[1]}</span>
                </span>
              </div>
          );
        }else{
          return (
            <div>--</div>
          )
        }
    };

  return (
    <>
        <div className={classes.liveContainer}>
            <div>
                <div className={`${classes.liveNum}`}>
                    {data ? (data[data.length - 1]?.lucky_number || '--'): '--'}
                </div>
                <div className={classes.liveStock}>
                    <div>
                        <p>Set</p>
                        {
                            data ?
                            _twodSetRateHighLight(data[data.length - 1]?.set)
                            :
                            '--'
                        }
                    </div>
                    <div>
                        <p>Value</p>
                        {
                            data ? 
                            _twodValueRateHighLight(data[data.length - 1]?.value)
                            :
                            '--'
                        }
                        {/* <p>5877<span className={classes.blinkaccent} style={{color: 'var(--accent-color)'}}>5</span>.9<span className={classes.blinkaccent} style={{color: 'var(--accent-color)'}}>3</span></p> */}
                    </div>
                </div>
                <div className={classes.time}>
                    {
                        time
                    }
                </div>
            </div>
        </div>
        <div className={classes.historyContainer}>
            {/* <History page="home" /> */}
            <div style={{height: '100%'}}>
                {
                    data?.length > 0 ?
                    <ul className={classes.historyList}>
                        {
                        data?.map(list => 
                            <li className={classes.listCard}>
                                <div className={classes.listContainer}>
                                    <div>
                                        <div>Time</div>
                                        <div>{moment(list?.end).format("hh:mm A")}</div>
                                    </div>
                                    <div>
                                        <div>Set</div>
                                        {
                                            _twodResultSetRateHighLight(list?.set)
                                        }
                                    </div>
                                    <div>
                                        <div>Value</div>
                                        {
                                            _twodResultValueRateHighLight(list?.value)
                                        }
                                    </div>
                                    {
                                        <div>
                                            <div>2D</div>
                                            <div className={classes.result} style={{color:'var(--accent-color)'}}>{list?.lucky_number || '--'}</div>
                                        </div>
                                    }
                                </div>
                                
                            </li>
                        )
                        }
                    </ul>
                    :
                    <NotFound text="No data!" />
                }
            </div>
        </div>
    </>
  )
}

export default TwoClose
