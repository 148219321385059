import LoginPage from 'pages/auth/LoginPage';
import HistoryPage from 'pages/HistoryPage';
import HomePage from 'pages/HomePage';
import DashboardHomePage from 'pages/dashboard/DashboardHomePage';
import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectRoute from './ProtectRoute';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import DashboardSettingPage from 'pages/dashboard/DashboardSettingPage';
import DownloadRedirect from 'utils/DownloadRedirect';

const Routers = () => {
  return (
    <Routes>
        <Route path='/auth/login' element={
            <LoginPage />
        }/>
        <Route path='/dashboard/home' element={
            <ProtectRoute>
                <DashboardHomePage />
            </ProtectRoute>
        }/>
        <Route path='/dashboard/setting' element={
            <ProtectRoute>
                <DashboardSettingPage />
            </ProtectRoute>
        }/>

        <Route path='/' element={
            <HomePage />
        }/>
        <Route path='/threed-history' element={
            <HistoryPage />
        }/>
        <Route path={'/twod-history'} element={
            <HistoryPage />
        }/>
        <Route path={'/privacy-policy'} element={
            <PrivacyPolicyPage />
        }/>
        <Route path='/download' element={<DownloadRedirect />}/>
        <Route path='*' element={<Navigate to="/" replace />}/>
    </Routes>
  )
}

export default Routers
