import React, { useEffect, useState } from 'react'
import classes from 'assets/styles/Live.module.css';
import {io} from "socket.io-client";
import { useDispatch, useSelector } from 'react-redux';
import { countTime } from 'utils/Helper';
import moment from 'moment';
import { authUnlockHandler } from 'store/slices/authUnlockSlice';
import History from './History';
import TwoClose from './TwoClose';
import { FiCheckCircle } from 'react-icons/fi';
import { setThreeValueHandler } from 'store/slices/threedStockSlice';
const Live = ({two_d_close_days}) => {
    const {lotteryType} = useSelector(state => state.type);
    const {value} = useSelector(state => state.authUnlock);
    const [data,setData] = useState(null);
    const [time,setTime] = useState("");
    const [keys,setKeys] = useState([]);
    const dispatch = useDispatch();
    const [animate,setAnimate] = useState(false);
    const { twodTimesValue } = useSelector(state => state.twodTimes);
    const {threedStockValue} = useSelector(state => state.threedStock);

    const _threedSetRateHighLight = (num) => {
        if(num){
            const [integerPart, decimalPart] = num.toString().split('.');
            return (
                <p>
                    {
                        Number(integerPart)?.toLocaleString('en-US')
                    }
                    .
                    <span>
                        <span style={{ color: 'var(--accent-color)' }}>{decimalPart[0]}</span>
                        <span>{decimalPart[1]}</span>
                    </span>
                </p>
            );
        }
    };

    const _threedValueRateHighLight = (num) => {
    if(num){
        const [integerPart, decimalPart] = num.toString().split('.');
        const formattedIntegerPart = new Intl.NumberFormat().format(integerPart);
        const integerPartArray = formattedIntegerPart.split('');
        const lastDigitIndex = integerPartArray.length - 1;
        integerPartArray[lastDigitIndex] = (
            <span key={lastDigitIndex} style={{ color: 'var(--accent-color)' }}>
            {integerPartArray[lastDigitIndex]}
            </span>
        );
    
        return (
            <p>
                {integerPartArray.map((digit, index) => (
                    <React.Fragment key={index}>{digit}</React.Fragment>
                ))}
                .
                <span>
                    <span>{decimalPart[0]}</span>
                    <span style={{ color: 'var(--accent-color)' }}>{decimalPart[1]}</span>
                </span>
            </p>
        );
    }
    };

    const _twodSetRateHighLight = (num) => {
    if(num){
        const [integerPart, decimalPart] = num.toString().split('.');
        const formattedIntegerPart = new Intl.NumberFormat().format(integerPart);
        const integerPartArray = formattedIntegerPart.split('');
        const lastDigitIndex = integerPartArray.length - 1;
        integerPartArray[lastDigitIndex] = (
            <span key={lastDigitIndex} style={{ color: 'var(--accent-color)' }}>
            {integerPartArray[lastDigitIndex]}
            </span>
        );
    
        return (
            <p>
                {integerPartArray.map((digit, index) => (
                    <React.Fragment key={index}>{digit}</React.Fragment>
                ))}
                .
                {decimalPart}
            </p>
        );
    }
    };

    const _twodValueRateHighLight = (num) => {
    if(num){
        const [integerPart, decimalPart] = num.toString().split('.');
        return (
            <p>
                {
                    Number(integerPart)?.toLocaleString('en-US')
                }
                .
                <span>
                    <span>{decimalPart[0]}</span>
                    <span style={{ color: 'var(--accent-color)' }}>{decimalPart[1]}</span>
                </span>
            </p>
        );
    }
    };

    useEffect(()=>{
        setData(null);
        let url = process.env.REACT_APP_DOMAIN
        const socket = io(url, {
            path: "/api/lottery/socket",
        });
        socket.connect();
        socket.on("change_two_d_number", res => {
            // console.log(res)
            if(lotteryType === "2D"){
                setData(res);
            }
        });
        socket.on("change_three_d_number", res => {
            // console.log(res)
            if(lotteryType === "3D"){
                setData(res);
            }
        });
        return () => {
            socket.disconnect();
        };
    },[lotteryType]);

    const addTenMinutes = (time) => {
        if(time){
            let momentTime = moment(time, 'hh:mm A');
            momentTime.add(10, 'minutes');
            return momentTime.format('hh:mm A')
        };
    };

    const betweenTimes = (currentTime,firstTime,secondTime) => {
        const currentFormat = moment(currentTime, 'hh:mm A');
        const firstFormat = moment(firstTime, 'hh:mm A');
        const secondFormat = moment(secondTime, 'hh:mm A');
        if(currentFormat.isSameOrAfter(firstFormat)  && currentFormat.isBefore(secondFormat)){
            return true;
        }else{
            return false;
        };
    };

    const endTimes = (currentTime,firstTime) => {
        const currentFormat = moment(currentTime, 'hh:mm A');
        const firstFormat = moment(firstTime, 'hh:mm A');
        if(currentFormat.isSameOrAfter(firstFormat)){
            return true;
        }else{
            return false;
        };
    };

    useEffect(()=>{
        let momentTime = moment(threedStockValue?.end, 'hh:mm A');
        let momentDate = moment(threedStockValue?.end);
        let currentTime = moment();

        if (currentTime.isSame(momentDate, 'day')) {
            let addedThreeHour = momentTime.add(3, 'hours');
            let myanmarTime = moment("06:30 PM", 'hh:mm A');
            if (addedThreeHour.isAfter(myanmarTime)) {
                if(threedStockValue?.id){
                    dispatch(setThreeValueHandler({}));
                };
            };
        }else{
            if(threedStockValue?.id){
                dispatch(setThreeValueHandler({}));
            };
        };
    },[threedStockValue]);
    
    useEffect(()=>{
        const displayTime = () => {
            const currentDateTime = moment().format('DD-MM-YYYY, dddd, hh:mm:ss A');
            const currentTime = moment().format('hh:mm A');

            let animate = true;
            twodTimesValue.forEach((time, index) => {
                const isLastTime = index === twodTimesValue.length - 1;
                const isTimeMatched = time
                    ? isLastTime
                        ? endTimes(currentTime, time)
                        : betweenTimes(currentTime, time, addTenMinutes(time))
                    : false;
                
                if (isTimeMatched) {
                    animate = false;
                }
            });

            setAnimate(animate);
            setTime('Updated : ' + currentDateTime);
        };
        const timeCount = setInterval(displayTime, 1000);
        return (()=> {
            clearInterval(timeCount);
        });
    },[twodTimesValue]);


    const unlockClickHandler = () => {
        if(keys.length !== 3){
            setKeys(prev => [...prev, 2]);
        };
    };

    useEffect(()=>{
        if(keys.length === 3){
            const countNumber = value.filter(num => num === 2).length;
            if (countNumber >= 3) {
                console.log(countNumber);
            } else {
                dispatch(authUnlockHandler([...value,...keys]));
            };
        };
    },[keys]);

    useEffect(()=>{
        setKeys([]);
    },[value]);

    const dayNumber = () => {
        const today = moment().isoWeekday();
        return today;
    };

    if(lotteryType === "2D"){
        if(two_d_close_days && two_d_close_days?.length > 0){
            if(two_d_close_days.includes(dayNumber())){
                return <TwoClose />
            };
        };
    };

  return (
    <>
        <div className={classes.liveContainer}>
            <div>
                {
                    threedStockValue?.id?
                    <div onClick={unlockClickHandler} className={classes.liveNum}>
                        {threedStockValue ? (threedStockValue?.lucky_number || (lotteryType === "3D"? '---': '--')): (lotteryType === "3D"? '---': '--')}
                    </div>:
                    <div onClick={unlockClickHandler} className={`${classes.liveNum} ${ (animate && data) ? classes.blinkText: ''}`}>
                        {data ? (data?.lucky_number || (lotteryType === "3D"? '---': '--')): (lotteryType === "3D"? '---': '--')}
                    </div>
                }
                {
                    threedStockValue?.id?
                    <div className={classes.liveStock}>
                        <div>
                            <p>Set</p>
                            {
                                threedStockValue ?
                                _threedSetRateHighLight(threedStockValue?.set):
                                '--'
                            }
                        </div>
                        <div>
                            <p>Value</p>
                            {
                                threedStockValue ? 
                                _threedValueRateHighLight(threedStockValue?.value):
                                '--'
                            }
                        </div>
                    </div>:
                    <div className={classes.liveStock}>
                        <div>
                            <p>Set</p>
                            {
                                data ?
                                (
                                    lotteryType === "3D"?
                                    _threedSetRateHighLight(data?.set):
                                    _twodSetRateHighLight(data?.set)
                                ):
                                '--'
                            }
                        </div>
                        <div>
                            <p>Value</p>
                            {
                                data ? 
                                (
                                    lotteryType === "3D"?
                                    _threedValueRateHighLight(data?.value):
                                    _twodValueRateHighLight(data?.value)
                                ):
                                '--'
                            }
                        </div>
                    </div>
                }
                <div className={classes.time}>
                    {
                       (!animate && time) && <FiCheckCircle color='var(--main-color)' style={{marginRight: '5px'}} size={17}/>
                    }
                    {
                        time
                    }
                </div>
            </div>
        </div>
        <div className={classes.historyContainer}>
            <History page="home" />
        </div>
    </>
  )
}

export default Live
